import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import abt from "../img/surabhi.png";
import NavBar from "../nav/nav";
import { Autoplay, Pagination } from "swiper";

import "./horoscopepage.css";

import w1 from "../img/wcu/astro.png";
import w2 from "../img/wcu/hq.png";
import w3 from "../img/wcu/trust.png";
import w4 from "../img/wcu/exp.png";
import w5 from "../img/wcu/pra.png";
import w6 from "../img/wcu/hol.png";
import { Helmet } from "react-helmet";
import aries from "../img/horoscope/aries.png";
import taurus from "../img/horoscope/taurus.png";
import gemini from "../img/horoscope/gemini.png";
import cancer from "../img/horoscope/cancer.png";
import leo from "../img/horoscope/leo.png";
import virgo from "../img/horoscope/virgo.png";
import libra from "../img/horoscope/libra.png";
import scorpio from "../img/horoscope/Scorpio.png";
import Sagittarius from "../img/horoscope/Saggitarius.png";
import capricorn from "../img/horoscope/Capricorn.png";
import aquarius from "../img/horoscope/Aquarius.png";
import pisces from "../img/horoscope/Pisces.png";
import s1 from "../img/service/1.png";
import s2 from "../img/service/2.png";
import s3 from "../img/service/3.png";
import s4 from "../img/service/4.png";
import s5 from "../img/service/pa.png";
import s6 from "../img/service/nu.png";
import s7 from "../img/service/re.png";

export default function horoscopepage() {
  return (
    <>
      <Helmet>
        <title>Weekly Horoscope Prediction By Dr. Surabhi Bhatnagar</title>
        <meta
          name="description"
          content="Blessings Astrology is the perfect destination for anyone seeking guidance in their life, whether it's love, career, or life in general."
        />
        <meta
          name="keywords"
          content="Horoscope Prediction, Aries horoscope, Taurus horoscope, taurus weekly horoscope, Gemini horoscope, Cancer horoscope, weekly Leo horoscope, Virgo horoscope, virgo weekly horoscope, weekly Libra horoscope"
        />

        <link
          rel="canonical"
          href="https://blessingsastrology.com/horoscope-prediction"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Blessings Astrology" />
        <meta
          property="og:title"
          content="Weekly Horoscope Prediction By Dr. Surabhi Bhatnagar"
        />
        <meta
          property="og:description"
          content="Blessings Astrology is the perfect destination for anyone seeking guidance in their life, whether it's love, career, or life in general."
        />
        <meta
          property="og:url"
          content="https://blessingsastrology.com/horoscope-prediction"
        />
        <meta
          property="og:image"
          content="https://blessingsastrology.com/static/media/horoscopebg.dc48382d7221fd269388.png"
        />
        <meta
          name="twitter:title"
          content="Weekly Horoscope Prediction By Dr. Surabhi Bhatnagar"
        />
        <meta
          name="twitter:description"
          content="Predict your weekly Horoscope By Dr. Surabhi Bhatnagar - Best Astrologer in India, Check your Moon and Ascendant Signs. Aries Horoscope Prediction."
        />
        <meta
          name="twitter:image"
          content="https://blessingsastrology.com/static/media/horoscopebg.dc48382d7221fd269388.png"
        />
      </Helmet>
      <section className="inner-section horoscope-page">
        <NavBar />
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Horoscope Prediction</li>
              </ul>
            </div>
          </div>
        </div>

        <section className="horoscope-section pt-4 py-lg-5">
          <div className="container">
            <div className="row">
              <div className="section-head text-center mb-0 col-12">
                <h1 className="pb-2">
                  <span>Weekly</span> Horoscope Prediction
                </h1>
                <h5>7th Oct - 13th Oct</h5>
                <p>Check your Moon and Ascendant Signs</p>
              </div>
              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={aries} alt="Astrology Services"></img>
                  </div>
                  <h3>Aries</h3>
                  <p className=" ">
                  This week, due to Saturn being placed in the eleventh house of gains, you will earn extra money if you work wisely. So, make a strategy and work accordingly. Your friends will be supportive but still you should be careful in choosing your spoken words. You are likely to get fame and more respect in society because of your own efforts, performance and success you will achieve. You will win the hearts and appreciation of your mentors and seniors.                  </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> White
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 2
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={taurus} alt="Astrology Services"></img>
                  </div>
                  <h3>Taurus</h3>
                  <p className=" ">
                  This week, Rahu will help you enjoy good health. Relatives will help you in financial problems if you are facing any. It is advised that you keep good relations with your near ones otherwise you can face mental stress. Saturn in the house of profession will help you gain favourable results in your career. All hindrances will be removed and the path to success will be clear. Your opponents will not bother you and huge appreciation is there.                               </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Green
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 5
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={gemini} alt="Astrology Services"></img>
                  </div>
                  <h3>Gemini</h3>
                  <p className=" ">
                  This week, due to Rahu being placed in the tenth house of profession, if you want to earn money to strengthen your financial position then you should invest only after thorough discussion with the experts. Due to Saturn in the ninth house of luck, you should prepare yourself with well analysed strategies and plans in order to achieve your goals. Keep your eyes and ears open at your workplace, someone might try to create hurdles in your work and give you stress.                          </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Peach
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 5
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={cancer} alt="Astrology Services"></img>
                  </div>
                  <h3>Cancer</h3>
                  <p className=" ">
                  This week, you will gain sudden money from new sources which will bring happiness. It is likely that a long due increment and promotion is given and this brings many emotional moments to you due to Jupiter being placed in the house of profession. Your seniors will appreciate you and you will be seen as an inspiration by your subordinates. You will be required to improve situations in your family with your intelligence when domestic issues take place.                  </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Yellow
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 3
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={leo} alt="Astrology Services"></img>
                  </div>
                  <h3>Leo</h3>
                  <p className=" ">
                  This week, you will feel highly emotional and hence open communication with others will be difficult. Due to Jupiter being placed in the house of profession, you will get better results in terms of finances. There are conjunctions of either increments or promotion in career. Focus on your earnings by taking the utmost advantage of the time. The favourable planetary yogas indicate enhancement of creativity and profitable expansion of your work.                     </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> White
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 2
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={virgo} alt="Astrology Services"></img>
                  </div>
                  <h3>Virgo</h3>
                  <p className=" ">
                  This week, due to Rahu being placed in the seventh house , you will suddenly gain money from new sources which will help you in many ways. This will enhance positivity in your mind and you will shower your family with gifts. It is advised to be careful with the investments. Plan to invest your hard earned money in steps even if it is in new business. Read the documents twice before signing. Spend as much time you can in activities you love the most.                    </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Green
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 5
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={libra} alt="Astrology Services"></img>
                  </div>
                  <h3>Libra</h3>
                  <p className=" ">
                  This week, a strong possibility of profit is seen due to Ketu in the twelfth house of investments, but it is advised that do not lend money to borrowers in awe of excess money you get. Due to Saturn in the fifth house, your seniors at the workplace will stand by you and help you in spite of you facing challenges in completing your work. Your open communication about the problems faced by you in meeting deadlines will let others help you.                      </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Pink
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 6
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={scorpio} alt="Astrology Services"></img>
                  </div>
                  <h3>Scorpio</h3>
                  <p className=" ">
                  This week, you will be full of enthusiasm and spirit and will be eager to learn something new. You will enjoy a good financial life. Due to Jupiter in the seventh house you will get many opportunities to earn money. Along with this, your honour and respect will increase. It is advised that you should control your speech and spoken words. If you have applied for any loan, then it is likely to be sanctioned this week which will help you to invest and benefit.                      </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Orange
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 1
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={Sagittarius} alt="Astrology Services"></img>
                  </div>
                  <h3>Sagittarius</h3>
                  <p className=" ">
                  This week, due to Saturn being placed in the third house, will be very good in economic aspects. You are very likely to get many opportunities to profit and strengthen your financial condition. Try to use such opportunities by making a proper strategy and planning about it so as to be future ready for any financial condition. You will get relief from ups and downs and unwanted stress in career and profession. Good unexpected changes will be seen.                  </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Red
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 9
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={capricorn} alt="Astrology Services"></img>
                  </div>
                  <h3>Capricorn</h3>
                  <p className=" ">
                  This week, due to Ketu being placed in the ninth house, you will be involved in charity and religious activities. You will organise a religious event in the family which will bring inner peace and positive thoughts. In career, you will set your goals higher than before but you need to work harder to accomplish them. You should not get disappointed with the slow results in the beginning. You will eventually win in the end. Your past investments might be slower in giving results but wait .                    </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Blue
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 4
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={aquarius} alt="Astrology Services"></img>
                  </div>
                  <h3>Aquarius</h3>
                  <p className=" ">
                  This week, you will be able to establish an understanding within your family with your efforts and a sense of harmony will be developed. You will also strengthen the social status of your family and you will succeed in earning a high reputation. Careerwise, you will feel very lucky. You will be appreciated and supported by your superiors and might be rewarded with the desired promotion. You can make a travel plan which will result in fatigue and stress hence try to avoid it if you can.                      </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Grey
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 7
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={pisces} alt="Astrology Services"></img>
                  </div>
                  <h3>Pisces</h3>
                  <p className=" ">
                  This week, due to Jupiter in the third house , you will come across many opportunities to add to your overall work capacity. You will also see an increase in your inner happiness. If you are managing foreign related work then you are likely to get immense success in forming connections with many sources and learning economic benefits from them. But you will need to adopt the right strategy.You will be energetic,warm and lively.                    </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Yellow
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 3
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <Col xs={12} className="section-head t-head mb-0 my-4">
                {/* <h4><span>What Do Our Clients Say</span> About Us?</h4> */}
                <h4>
                  <span>Our</span> Services
                </h4>
              </Col>

              <div className="col-12">
                <Swiper
                  spaceBetween={30}
                  loop={true}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                  }}
                  autoplay={{
                    delay: 5000,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay, Pagination]}
                  className="mySwiper testimonial-swiper services-swiper pt-5">
                  <SwiperSlide>
                    <div className="">
                      <div className="service-card">
                        <div className="icon-wrapper">
                          <img src={s1} alt="Astrology Services"></img>
                        </div>
                        <h3>Astrology</h3>
                        <p>
                          Astrology, also known as Jyotish, is an ancient and
                          divine knowledge that can see into our past...
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                          <a
                            href="/astrology-service"
                            className="button button-2">
                            Read More{" "}
                          </a>
                          <a
                            href="/book-an-appointment"
                            className="button button-2">
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="">
                      <div className="service-card">
                        <div className="icon-wrapper">
                          <img src={s2} alt="Vastu Shastra services"></img>
                        </div>
                        <h3>Vastu Shastra</h3>
                        <p>
                          Vastu Shastra is a divine science that we have used
                          since ancient times to plan and build our homes...
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                          <a
                            href="/vastu-shastra-service"
                            className="button button-2">
                            Read More
                          </a>
                          <a
                            href="/book-an-appointment"
                            className="button button-2">
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="">
                      <div className="service-card">
                        <div className="icon-wrapper">
                          <img
                            src={s3}
                            alt="Handwriting and Signature Analysis (Astrology)"></img>
                        </div>
                        <h3>Handwriting & Signature Analysis</h3>
                        <p>"We can judge a person by looking at his...</p>
                        <div className="d-flex align-items-center justify-content-between">
                          <a
                            href="/handwriting-signature-analysis"
                            className="button button-2">
                            Read More
                          </a>
                          <a
                            href="/book-an-appointment"
                            className="button button-2">
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="">
                      <div className="service-card">
                        <div className="icon-wrapper">
                          <img src={s4} alt="Tarot Card Reading"></img>
                        </div>
                        <h3>Tarot</h3>
                        <p>
                          Tarot card reading is an ancient technique that was
                          popular in Europe centuries ago and has now spread all
                          ...
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                          <a href="/tarot-service" className="button button-2">
                            Read More
                          </a>
                          <a
                            href="/book-an-appointment"
                            className="button button-2">
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="">
                      <div className="service-card">
                        <div className="icon-wrapper">
                          <img src={s5} alt="Palmistry Services" />
                        </div>
                        <h3>Palmistry</h3>
                        <p>
                          Palmistry is an age old technique in which an
                          astrologer reads and analyzes the palm of the
                          native....
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                          <a
                            href="/palmistry-service"
                            className="button button-2">
                            Read More
                          </a>
                          <a
                            href="/book-an-appointment"
                            className="button button-2">
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="">
                      <div className="service-card">
                        <div className="icon-wrapper">
                          <img src={s6} alt="Numerology services" />
                        </div>
                        <h3>Numerology</h3>
                        <p>
                          Numerology is the wing of astrology where we consider
                          numbers and predict the past, present and future.
                          Numerology helps people...
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                          <a
                            href="/numerology-service"
                            className="button button-2">
                            Read More
                          </a>
                          <a
                            href="/book-an-appointment"
                            className="button button-2">
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="">
                      <div className="service-card">
                        <div className="icon-wrapper">
                          <img src={s7} alt="Reiki Healing Services" />
                        </div>
                        <h3>Reiki Healing</h3>
                        <p>
                          It is the japanese technique which makes use of hands
                          and invokes energy and blessings from the universe. It
                          is an age-old...
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                          <a
                            href="/reikihealing-service"
                            className="button button-2">
                            Read More
                          </a>
                          <a
                            href="/book-an-appointment"
                            className="button button-2">
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}
